import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import android from "../assets/android.svg";
import apple from "../assets/apple.svg";
import QRCode from "react-qr-code";
import { TEST_DOWNLOAD_URL } from "../lib/urls";

interface PopupProps {
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="rounded-xl shadow-lg relative transform transition-transform duration-300 ease-out scale-95 opacity-100 animate-fade-in cursor-pointer bg-black bg-opacity-70 p-6">
        <button
          className="absolute top-0 right-2 text-white text-lg font-bold transform transition-transform duration-300 ease-out hover:scale-110 text-6xl"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex items-center justify-center gap-2 my-1 flex-col">
          <span className="text-lg md:text-xl font-bold font-custom text-white">
            {t("downloadAppNow")}
          </span>
          <span className="text-xs font-bold font-custom text-white opacity-70 mb-1">
            {t("scan")}
          </span>
          <QRCode
              size={150}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={TEST_DOWNLOAD_URL}
              viewBox={`0 0 150 150`}
              className="p-2 bg-white rounded"
            />
          <div className="flex items-center justify-center gap-2 my-1 opacity-50">
            <img src={android} alt="Big Rummy" className="h-[12px]" />
            <img src={apple} alt="Big Rummy" className="h-[12px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
