"use client";

import { RetroGrid } from "../components/magicui/retro-grid";
import { enableAnimations } from "../lib/utils";

export function RetroGridDemo() {

  return (
    <div className="fixed bottom-0 md:mt-0 mt-10 flex h-[300px] md:h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background md:shadow-xl">
      {enableAnimations() && <RetroGrid />}
    </div>
  );
}
