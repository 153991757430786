import FixedBottomBar from "../components/bottom";
import { HeaderSection } from "../components/header";
import MainSection from "../components/main";
import ParticlesBackground from "../components/particles";
import { MarqueeDemo } from "../components/testimonials";

import { RetroGridDemo } from "../components/bottom-retro";

const HomePage = () => {
  return (
    <div className="min-h-screen flex items-center bg-gradient-radial flex-col overflow-hidden h-full justify-between">
      <ParticlesBackground />
      <MainSection />
      <RetroGridDemo />
    </div>
  );
};

export default HomePage;
