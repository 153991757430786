import { useState } from "react";
import { useTranslation } from "react-i18next";
import bigr from "./../assets/appLogo.png";
import iconDownload from "./../assets/iconDownload.svg";
import Popup from "../components/popup-download";
import { useDownload } from "../lib/downloadUtils";

const FixedBottomBar = () => {
  const { t } = useTranslation();
  const { onDownload } = useDownload();
  const [showPopup, setShowPopup] = useState(false);

  const handleDownloadClick = async () => {
    const result = await onDownload();
    if (result === "popup") {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="bg-[#771425] text-white text-center py-4 px-6 md:px-8  bg-clip-padding backdrop-filter backdrop-blur-xs bg-opacity-100 rounded-3xl z-50 3xl:mt-20">
        <div className="flex flex-col items-center justify-center my-1">
          <div className="flex items-center justify-center gap-1 my-0 md:my-1 flex-col">
            <span className="text-2xl font-bold font-custom text-amber-500">
              NEW VERSION AVAILABLE
            </span>
            <div className="w-full max-w-xs flex flex-col gap-2">
              <span className="text-lg font-custom mt-2 opacity-50 leading-4 text-center">
                Fixed minor bugs for enhanced stability.
              </span>
              <span className="text-lg font-custom mt-2 opacity-50 leading-4 text-center">
                Optimized the interface design for improved usability.
              </span>
              <span className="text-lg font-custom mt-2 opacity-50 leading-4 text-center">
                Made the experience smoother and more seamless.
              </span></div>
            <span className="text-lg font-custom opacity-90 mt-2 font-bold mb-3">
              Click here to update
            </span>
          </div>
          <button
            className="relative inline-flex h-16 md:h-16 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-50 transform transition-all duration-300 hover:scale-110"
            onClick={handleDownloadClick}
          >
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#eccd89_0%,#856935_50%,#edc976_100%)]" />
            <span className="text-amber-200 inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-70 px-6 md:px-8 font-bold backdrop-blur-3xl text-3xl gap-4">
              <span>Update Now</span>
              <img
                src={iconDownload}
                alt="Update"
                className="h-8 w-8 object-contain"
              />
            </span>
          </button>
        </div>
      </div>
      {showPopup && <Popup onClose={handleClosePopup} />}
    </>
  );
};

export default FixedBottomBar;
