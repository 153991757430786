import AlphaVideo from "./alphaVideo";
import rayw from ".././assets/ray.webm";
import { useTranslation } from "react-i18next";
import FixedBottomBar from "./bottom";

const MainSection = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center flex-col pt-10 2xl:mt-20">
      <img
        src="bg2-min.png"
        alt="Pak555"
        className="absolute top-0"
      />
      <div className="flex items-center justify-center relative z-10 animate-scale md-0 mt-10">
        <AlphaVideo
          webmSrc={rayw}
          mp4Src={rayw}
          loop={true}
          width="600"
          height="500"
          className="absolute filter brightness-0 invert opacity-80"
        />
        <div className="w-[300px] h-[400-px] md:w-[600px] md:h-[500-px] flex justify-center items-center">
          <img
            src="pak555NewLogo.png"
            alt="Pak555"
            className="relative"
            width="400"
            height="400"
          />
        </div>
      </div>
      <FixedBottomBar />
    </div>
  );
};

export default MainSection;
