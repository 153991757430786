"use client";

import { enableAnimations } from "../lib/utils";
import Particles from "./magicui/particles";
import { isMobile } from "react-device-detect";

const ParticlesBackground = () => {
  if (!enableAnimations()) {
    return <div></div>;
  }
  return (
    <div className="w-full h-full absolute pointer-events-none md:pointer-events-auto">
      <Particles
        className="absolute inset-0 w-full h-full z-0"
        quantity={isMobile ? 200 : 500}
        ease={20}
        color="#fff491"
        refresh
      />
    </div>
  );
};

export default ParticlesBackground;
