import { isMobile } from "react-device-detect";
import {  TEST_DOWNLOAD_URL } from "./urls";

export function useDownload() {
  const onDownload = async () => {

    if (!isMobile) {
      return "popup";
    } else {
      window.open(TEST_DOWNLOAD_URL, "_blank");
    }
  };

  return { onDownload };
}
